import * as React from "react";
import Layout from "../components/layout";
import LocationNav from "../components/locationNav";
import { graphql } from "gatsby";
const IndexPage = ({ data }) => {
  console.log({ data });
  const { allStories, usStories } = data;
  const totalCount = allStories.totalCount;
  return (
    <Layout>
      <LocationNav />
      <p className="prose prose-2xl mt-10">
        Millions of women have abortions, yet many feel secretive and alone in
        their decision. By bringing together personal stories,{" "}
        <strong>Project Voice</strong> hopes to establish a resource of sharing
        and support. The more people who share their stories here, the more
        accurately we can represent the multiplicities of abortion experiences.
      </p>
      <p className="prose prose-2xl mt-10">
        Project Voice currently features <strong>{usStories.totalCount}</strong>{" "}
        stories from the United States, from{" "}
        <strong>{usStories.distinctStates.length}</strong> states, as well as{" "}
        <strong>{allStories.totalCount - usStories.totalCount}</strong> stories
        outside the United States.
      </p>
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery {
    allStories: allContentfulStory {
      totalCount
    }
    usStories: allContentfulStory(filter: { country: { eq: "US" } }) {
      totalCount
      distinctStates: distinct(field: state)
    }
  }
`;
export default IndexPage;
